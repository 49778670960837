.application-error {
    position: relative;
    top: 300px;
    width: 100%;
    height: 280px;
    margin: auto;
    text-align: center;
  }
  
  .application-error > .message {
    position: relative;
    width: 50%;
    border:none;
    margin: auto;
  }

  .application-error > .message > .name {
    font-size: 28px;
  }

  .application-error > .message > .content {
    position: relative;
    color:rgb(80, 80, 80);
    top: 10px;
    font-size: 20px;
  }
  
  
  .application-error > .stack {
    color:rgb(170, 170, 170);
    position: relative;
    width: 60%;
    top: 35px;
    margin: auto;
    text-align: center;    
  }

  .application-error > .stack > .error-code {
    font-size: 13px;
  }

  .application-error > .stack > h3 {
    top: 5px;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
  }
  .application-error > .stack > .details { 
    top: 5px;
    font-size: 12px;
    position: relative;
  }
 