.dual-listbox__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.dual-listbox__container .listbox__container {
    width: 45%;
}
.dual-listbox__container .dual-listbox__button-container {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dual-listbox__container .dual-listbox__button-container button {
    margin: 5px;
}
