/* Slider */
.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    margin-right: 9%;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
}

.slick-list:focus
{
    outline: none;
}

.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}

.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide
{
    float: right;
}

.slick-initialized .slick-slide
{
    display: block;
}

.slick-loading .slick-slide
{
    visibility: hidden;
}

.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    background: transparent;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

.slick-prev
{
    left: -5px;
    border-right: 15px solid #000;
}

[dir='rtl'] .slick-prev
{
    right: -5px;
    left: auto;
}

.slick-next
{
    right: -10px;
    border-left: 15px solid #000;
}

[dir='rtl'] .slick-next
{
    right: auto;
    left: -5px;
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 10px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}

.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

/* docs */
.slide-text {
    background: #fff;
    color: #000;
    border: #CFD2D6;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    line-height: 62px;
    margin: 10px;
    padding: 2%;
    padding-left: 10%;
    padding-right: 5%;
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.slide-text .searchIcon {
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 19px;
}

.checkboxIcon[type="checkbox"]{
    -webkit-appearance: radio;
    -moz-appearance: radio;
}

.slide-text .checkboxIcon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
}

.slide-text .diagramIcon {
    position: absolute;
    top: 35%;
    left: 8px;
    font-size: 19px;
}

.variable-width .slick-slide p {
    background: #00558B;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}

.slick-dots {
    margin-left: 0;
}

.slick-vertical .slick-slide {
    height: 180px;
}