.simple-filter-section-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
}

.simple-filter-section-box > button {
    margin-left: 1em;
}

.simple-filter-section-box > a {
    margin-left: 1em;
}

.simple-filter-section-box > input {
    margin-left: 1em;
    font-size: 14px;
}

.checkboxCustom[type="checkbox"]{
    margin-top: 8px;
    font-size: 14px;
}
.strips-container {
    border: 1px solid rgb(207, 210, 214);
    padding: 8px 8px;
    margin: 8px 0px;
}
.strip {
    border: 1px solid rgb(207, 210, 214);
    /* border: 0.15rem solid #398FD1; */
    padding: 8px;
    margin: 8px;
    background-color: white;
}

.strip:hover {
    background-color: #e7e9ea;
}
.strip-header {
    border-bottom: 1px solid rgb(207, 210, 214);
    /* border-bottom: 0.15rem solid #398FD1; */
    font-weight: bold;
    padding: 0 0 4px 0;
    margin: 0 0 8px 0;
}
.react-datepicker__time-list {
    padding-left: 5px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.result-item-title {
    font-weight: bold;
}

.result-item {
    white-space: pre-wrap;
}

.clipboard-copy {
    color: rgb(140, 140, 140);
}

.clipboard-copied {
    color: rgb(84, 171, 66);
}
.result-header-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.result-header-bar > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;   
}

.result-header-bar > div > div {
    margin-left: 1em;
}

.result-header-bar > div > div:first-child {
    margin-left: 0;
}
.error-color {
    color: #D52B1E !important;
}

.error-bottom-color {
    border-bottom: 0.15rem solid #D52B1E !important;
    font-weight: normal !important;
}

.files-border{
    border: 1px solid #000000;
    padding-top: 10px;
}

.files-border ul li{
    padding-bottom: 10px;
}

.table-error{
    width: 100%;
    table-layout: fixed;
}

.table-error tr td:first-child{
    width: 15%;
    font-weight: bold;
}

.table-error tr td p{
    word-wrap: break-word;
}

.responsive-table{
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
}
/* Slider */
.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;
    margin-right: 9%;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
}

.slick-list:focus
{
    outline: none;
}

.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after
{
    display: table;
    content: '';
}

.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide
{
    float: right;
}

.slick-initialized .slick-slide
{
    display: block;
}

.slick-loading .slick-slide
{
    visibility: hidden;
}

.slick-vertical .slick-slide
{
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    top: 50%;
    display: block;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    background: transparent;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

.slick-prev
{
    left: -5px;
    border-right: 15px solid #000;
}

[dir='rtl'] .slick-prev
{
    right: -5px;
    left: auto;
}

.slick-next
{
    right: -10px;
    border-left: 15px solid #000;
}

[dir='rtl'] .slick-next
{
    right: auto;
    left: -5px;
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 10px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}

.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '\2022';
    text-align: center;
    opacity: .25;
    color: black;
}

.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

/* docs */
.slide-text {
    background: #fff;
    color: #000;
    border: #CFD2D6;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    line-height: 62px;
    margin: 10px;
    padding: 2%;
    padding-left: 10%;
    padding-right: 5%;
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.slide-text .searchIcon {
    position: absolute;
    top: 6px;
    right: 8px;
    font-size: 19px;
}

.checkboxIcon[type="checkbox"]{
    -webkit-appearance: radio;
    -moz-appearance: radio;
}

.slide-text .checkboxIcon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
}

.slide-text .diagramIcon {
    position: absolute;
    top: 35%;
    left: 8px;
    font-size: 19px;
}

.variable-width .slick-slide p {
    background: #00558B;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
}

.slick-dots {
    margin-left: 0;
}

.slick-vertical .slick-slide {
    height: 180px;
}
.dashboard-margin{
    margin-top:6px;
}

.row-dashboard {
    margin-top: 6px;
}

.chart-container {
    position: relative;
    margin: auto;
    height: auto;
    width: 45vw;
    float: left;
}

.chart-main-container {
    position: relative;
    margin: auto;
    height: auto;
    width: 45vw;
    float: left;
}

.chart-margin {
    margin-top:16px;
}

.summery-block {
    width: 14% !important;
    margin-right: 5% !important;
    }

.navigation-link {
    color: #000 !important;
}

.navigation-link:hover {
    color: #3C5BA3 !important;
}

.system-block {
    padding: 0.5rem;
    border: 0.0625rem solid #cfd2d6;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: top;
}

.system-block .system-data {
    text-align: center;
    padding: 0.25rem;
    font-weight: 400;
    font-size: 32px;
}

.arrow {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

.arrow-right {
    border-left: 15px solid #000;
    right: 0;
}
.arrow-left {
    border-right: 15px solid #000;
    left: 0;
}

.arrow-container {
    font-size: 24px;
}

.arrow-header {
    text-align: center;
}
.application-error {
    position: relative;
    top: 300px;
    width: 100%;
    height: 280px;
    margin: auto;
    text-align: center;
  }
  
  .application-error > .message {
    position: relative;
    width: 50%;
    border:none;
    margin: auto;
  }

  .application-error > .message > .name {
    font-size: 28px;
  }

  .application-error > .message > .content {
    position: relative;
    color:rgb(80, 80, 80);
    top: 10px;
    font-size: 20px;
  }
  
  
  .application-error > .stack {
    color:rgb(170, 170, 170);
    position: relative;
    width: 60%;
    top: 35px;
    margin: auto;
    text-align: center;    
  }

  .application-error > .stack > .error-code {
    font-size: 13px;
  }

  .application-error > .stack > h3 {
    top: 5px;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
  }
  .application-error > .stack > .details { 
    top: 5px;
    font-size: 12px;
    position: relative;
  }
 
.result-item-title {
    font-weight: bold;
}
.result-header-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.result-header-bar > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;   
}

.result-header-bar > div > div {
    margin-left: 1em;
}

.result-header-bar > div > div:first-child {
    margin-left: 0;
}
.selected-table-row {
  font-size: 14px;
  color: #282828;
  text-align: left;
  background-color: #f0f7fb !important;
  border: solid 0px #64b2d4;
  box-shadow: inset 0px 0px 0px 1px #64b2d4;
}

.details-container {
  display: flex;
  flex-direction: column;
}

.react-dual-listbox {
  display: flex;
}

.rdl-list-box {
  flex-grow: 1;
}

.rdl-filter,
.rdl-control {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.rdl-actions-right,
.rdl-actions-left {
  display: flex;
  flex-direction: column;
}

.rdl-actions {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
}

.rdl-control-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

.details-buttons {
  align-self: flex-end;
  margin-top: 5px;
}

.listbox {
  padding: 5px;
}

.gr-name-input {
  width: 100%;
}
.details-table {
  width: 100%;
  margin-bottom: 0px;
}

.gr-name-edition-title {
  width: 100px;
}

.delete-btn,
.new-group-button {
  margin-left: 5px;
}

#toast-container {
  top: 70px;
}

.header-button {
  margin-bottom: 5px;
}

.rt-resizable-header-content {
  height: 20px;
}

.error {
  border: 2px solid red !important;
}

input:focus {
  outline: none;
}

.controls {
  align-self: flex-end;
  justify-content: space-between;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: blur(2px) url(#gaussian-blur);
  -webkit-filter: blur(2px) url(#gaussian-blur);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  width: 400px;
  padding: 20px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  background-color: #003e5e;
  border: 2px solid #003e5e;
  color: #fff;
  font-size: 14px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  height: 32px;
  margin-right: 10px;
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nodeLabel {
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.listbox__container {
    display: flex;
    flex-direction: column;
}

.listbox__title {
    height: 1.5rem;
    width: 100%;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.listbox__items {
    overflow-y: scroll;
}

.listbox__items > ul {
    padding-left:1rem;
    padding-right:1rem;
}

.listbox__items > ul > li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3rem;
    padding-left:1rem;
    padding-right:1rem;
    border-top: #64b2d4 1px solid;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}
.listbox__items > ul > li:last-of-type  {
    border-bottom: #64b2d4 1px solid;
}
.listbox__items > ul > li.listbox__item--active {
    background-color:lightblue;
}
.dual-listbox__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.dual-listbox__container .listbox__container {
    width: 45%;
}
.dual-listbox__container .dual-listbox__button-container {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dual-listbox__container .dual-listbox__button-container button {
    margin: 5px;
}

.becode-assign-item__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.becode-assign-item__action i {
    margin-right: 1rem;
    margin-left: 1rem;
}
.becode-assign-item__tooltip {
    position: absolute;
    display: inline-block;
    border-bottom: 1px dotted black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.becode-assign-item__tooltip-text {
    overflow-y: scroll;
    visibility: hidden;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #64b2d4;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    margin-top: 1rem;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.becode-assign-item__tooltip-text ul {
    padding-top: 0.2rem;
}

.becode-assign-item__tooltip-text ul, li {
    list-style: none;
    padding-left: 0.5rem;
    text-align: left;
    margin-right: 1rem;
    /*margin-left: 1rem;*/
}


.becode-assign-item__container:hover .becode-assign-item__tooltip-text {
    visibility: visible;
}
.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 150px;
	padding: 20px;
	color: white;
}

.App-title {
	font-size: 1.5em;
}

.App-intro {
	font-size: large;
}

.nopadding {
	padding: 0 !important;
}

.display-table {
	display: table;
}
.form-control-select {
	display: block;
	width: 100%;
	font-size: 14px;
	line-height: 1.25;
	color: #000;
	background-color: #fff;
	background-image: none;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	transition: border-color ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

form.filter-search-criteria fieldset .form-group > .form-control-select {
    width: 50%;
    display: inline-block;
    margin: 0;
}

@media (max-width: 1287px) and (min-width: 768px) {
	form.filter-search-criteria fieldset .form-group > .form-control-select {
		width: 100%;
		display: block;
		margin: 0 0 0.25rem 0;
	}
}


@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

