.result-header-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.result-header-bar > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;   
}

.result-header-bar > div > div {
    margin-left: 1em;
}

.result-header-bar > div > div:first-child {
    margin-left: 0;
}