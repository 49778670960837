.error-color {
    color: #D52B1E !important;
}

.error-bottom-color {
    border-bottom: 0.15rem solid #D52B1E !important;
    font-weight: normal !important;
}

.files-border{
    border: 1px solid #000000;
    padding-top: 10px;
}

.files-border ul li{
    padding-bottom: 10px;
}

.table-error{
    width: 100%;
    table-layout: fixed;
}

.table-error tr td:first-child{
    width: 15%;
    font-weight: bold;
}

.table-error tr td p{
    word-wrap: break-word;
}

.responsive-table{
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
}