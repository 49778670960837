.dashboard-margin{
    margin-top:6px;
}

.row-dashboard {
    margin-top: 6px;
}

.chart-container {
    position: relative;
    margin: auto;
    height: auto;
    width: 45vw;
    float: left;
}

.chart-main-container {
    position: relative;
    margin: auto;
    height: auto;
    width: 45vw;
    float: left;
}

.chart-margin {
    margin-top:16px;
}

.summery-block {
    width: 14% !important;
    margin-right: 5% !important;
    }

.navigation-link {
    color: #000 !important;
}

.navigation-link:hover {
    color: #3C5BA3 !important;
}

.system-block {
    padding: 0.5rem;
    border: 0.0625rem solid #cfd2d6;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: top;
}

.system-block .system-data {
    text-align: center;
    padding: 0.25rem;
    font-weight: 400;
    font-size: 32px;
}

.arrow {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    position: absolute;
}

.arrow-right {
    border-left: 15px solid #000;
    right: 0;
}
.arrow-left {
    border-right: 15px solid #000;
    left: 0;
}

.arrow-container {
    font-size: 24px;
}

.arrow-header {
    text-align: center;
}