.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 80px;
}

.App-header {
	background-color: #222;
	height: 150px;
	padding: 20px;
	color: white;
}

.App-title {
	font-size: 1.5em;
}

.App-intro {
	font-size: large;
}

.nopadding {
	padding: 0 !important;
}

.display-table {
	display: table;
}
.form-control-select {
	display: block;
	width: 100%;
	font-size: 14px;
	line-height: 1.25;
	color: #000;
	background-color: #fff;
	background-image: none;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	-webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

form.filter-search-criteria fieldset .form-group > .form-control-select {
    width: 50%;
    display: inline-block;
    margin: 0;
}

@media (max-width: 1287px) and (min-width: 768px) {
	form.filter-search-criteria fieldset .form-group > .form-control-select {
		width: 100%;
		display: block;
		margin: 0 0 0.25rem 0;
	}
}


@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
